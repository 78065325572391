<template>
  <div class="py-16">
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          @click:row="handleRowClick"
          :headers="headers"
          :items="appeals"
          class="elevation-1"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import AppealsService from "@/services/appeals.service";

  export default {
    name: "NewAppeals",
    data() {
      return {
        loading: false,
        headers: [
          {
            text: "Client",
            value: "trade_name",
          },
          {
            text: "Legal Name",
            value: "legal_name",
          },
          {
            text: "Client ID",
            value: "id",
          },
          {
            text: "Denial Letter Uploaded Date",
            value: "upload_date",
          },
          {
            text: "Age",
            value: 'age',
          }
        ],
        appeals: []
      }
    },
    methods: {
      async getAppeals() {
        this.loading = true;

        try {
          this.appeals = await AppealsService.getNewAppeals();
        } finally {
          this.loading = false;
        }
      },
      handleRowClick(row) {
        this.$router.push(`/clients/${row.id}/appeals`)
      },
    },
    created() {
      this.getAppeals();
    },
  };
</script>


<style scoped>

</style>